import moment from "moment";
import { useEffect, useState } from "react";
import { TbNotes } from "react-icons/tb";
import ModalNoteInbox from "./ModalNoteInbox";
import { LuMinusCircle, LuPlusCircle } from "react-icons/lu";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { getWarehouseInboxDetail } from "../../../../services/centralWarehouseApi";
import { useParams } from "react-router-dom";

const InboxDetailWarehouse = () => {
  const { id } = useParams();

  const [selectedNote, setSelectedNote] = useState(null);

  const validAccept = () => {
    const canAccept = true;
    // loop through  products,
    // kalo ada yg qty received < qty sent && reason===0 change canAccept = false
    return canAccept;
  };

  const {
    mutate: mutateDetail,
    data: dataDetail,
    isLoading,
  } = useMutation({
    mutationFn: getWarehouseInboxDetail,
  });

  useEffect(() => {
    mutateDetail({ id: Number(id) });
  }, [id]);

  return (
    <>
      <div className="flex gap-2 mb-4">
        <div className="w-full">
          {dataDetail && !isLoading ? (
            <>
              <div className="flex gap-2 w-full">
                <div className="flex gap-2 items-center w-full">
                  <p className="w-1/3">Transaction ID</p>
                  <p>: {dataDetail.header.transaction_number}</p>
                </div>
                <div className="flex gap-2 items-center w-full">
                  <p className="w-1/3">From</p>
                  <p>: {dataDetail.header.source}</p>
                </div>
              </div>
              <div className="flex gap-2 w-full">
                <div className="flex gap-2 items-center w-full">
                  <p className="w-1/3">Date</p>
                  <p>
                    :{" "}
                    {dataDetail.header.date !== ""
                      ? moment(dataDetail.header.date).format("DD MMMM YYYY")
                      : ""}
                  </p>
                </div>
                <div className="flex gap-2 items-center w-full">
                  <p className="w-1/3">To</p>
                  <p>: {dataDetail.header.target}</p>
                </div>
              </div>
              <div className="flex gap-2 w-full">
                <div className="flex gap-2 items-center w-full">
                  <p className="w-1/3">Transaction Type</p>
                  <p>: {dataDetail.header.transaction_type}</p>
                </div>
                <div className="flex gap-2 items-center w-full">
                  <p className="w-1/3">User</p>
                  <p>: {dataDetail.header.created_by}</p>
                </div>
              </div>
            </>
          ) : isLoading ? (
            <CircularProgress size={20} />
          ) : null}
        </div>
        <div className="basis-1/3 flex flex-col justify-center">
          {dataDetail && !isLoading ? (
            <>
              {dataDetail.header.status === 1 ? (
                <div className="flex gap-4 items-center">
                  <button className="w-full border-2 rounded-md p-2 border-red-500 text-red-500 hover:bg-red-100">
                    Reject
                  </button>
                  <button
                    className="w-full border-2 rounded-md p-2 border-blue-500 bg-blue-500 text-white hover:bg-gray-300 disabled:bg-gray-300 disabled:border-gray-300"
                    disabled={validAccept} //{/* disabled if ada yg qty received < qty sent but reason empty */}
                  >
                    Accept
                  </button>
                </div>
              ) : dataDetail.header.status === 2 ? (
                <div className="w-full border-2 rounded-md p-2 border-blue-500 text-blue-500 bg-blue-100 text-center">
                  Accepted
                </div>
              ) : (
                <div className="w-full border-2 rounded-md p-2 border-red-500 text-red-500 bg-red-100 text-center">
                  Rejected
                </div>
              )}
            </>
          ) : null}
          {dataDetail && !isLoading ? (
            dataDetail.header.status === 1 ? null : (
              <p className="text-left block mt-2">
                {dataDetail.header.status === 2 ? "Accepted" : "Rejected"} in:{" "}
                {dataDetail.header.date === ""
                  ? moment().format("DD MMMM YYYY")
                  : moment(dataDetail.header.date).format("DD MMMM YYYY")}
              </p>
            )
          ) : null}
        </div>
      </div>

      <p className="font-bold">Product Information</p>
      <hr className="my-2" />

      <div className="mb-4">
        <div className="flex w-full mb-4">
          <div className="w-full">
            <div className="block overflow-x-auto w-full rounded-md">
              <table className="w-full overflow-x-auto rounded-md border-collapse">
                <thead className="bg-blue-500 text-white">
                  <th className="py-2 text-center">No</th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Product Name
                  </th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Batch Number
                  </th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Expired Date
                  </th>
                  <th className="py-2 text-center">UOM</th>
                  <th className="py-2 text-center">Qty Sent</th>
                  <th className="py-2 text-center">Qty Received</th>
                  <th className="py-2 text-center">Qty Back</th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Final Price
                  </th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Total
                  </th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Reason
                  </th>
                  <th className="py-2 text-center">Notes</th>
                </thead>
                <tbody>
                  {dataDetail && !isLoading && dataDetail.product ? (
                    dataDetail.product.map((item, i) => (
                      <tr key={i}>
                        <td className="py-2 px-3 text-center border">
                          {i + 1}
                        </td>
                        <td className="py-2 px-3 text-left border">
                          placeholder
                        </td>
                        <td className="py-2 px-3 text-left border">
                          placeholder
                        </td>
                        <td className="py-2 px-3 text-left border">
                          placeholder
                        </td>
                        <td className="py-2 px-3 text-center border">
                          placeholder
                        </td>
                        <td className="py-2 px-3 text-center border">
                          placeholder
                        </td>
                        <td className="py-2 px-3 text-center border">
                          {dataDetail.header.status === 1 ? (
                            <div className="flex justify-center bg-slate-100 rounded-md border-2 p-1">
                              <button
                                //   disabled={addProductArr[i].quantity === 0}
                                className="text-red-500 hover:text-red-700"
                                //   onClick={() => {
                                //     changeStock(addProductArr[i].id, "min");
                                //   }}
                              >
                                <LuMinusCircle className="text-[16px]" />
                              </button>
                              <input
                                type="text"
                                className="bg-slate-100 p-2 w-[80px] text-center"
                                value={0}
                                //   value={addProductArr[i].quantity}
                                //   onChange={(e) =>
                                //     changeStock(
                                //       addProductArr[i].id,
                                //       "text",
                                //       e.target.value
                                //     )
                                //   }
                              />
                              <button
                                className="text-blue-500 hover:text-blue-700"
                                //   onClick={() => {
                                //     changeStock(addProductArr[i].id, "add");
                                //   }}
                              >
                                <LuPlusCircle className="text-[16px]" />
                              </button>
                            </div>
                          ) : (
                            0
                          )}
                        </td>
                        <td className="py-2 px-3 text-center border">
                          placeholder
                        </td>
                        <td className="py-2 px-3 text-left border">
                          placeholder
                        </td>
                        <td className="py-2 px-3 text-left border">
                          placeholder
                        </td>
                        <td className="py-2 px-3 text-left border">
                          {dataDetail.header.status === 1 ? (
                            <Select value={0} disabled sx={{ width: "100%" }}>
                              <MenuItem value={0} disabled>
                                Choose Reason
                              </MenuItem>
                              <MenuItem value={1}>Barang Hilang</MenuItem>
                              <MenuItem value={2}>Barang Rusak</MenuItem>
                            </Select>
                          ) : (
                            0
                          )}
                        </td>
                        <td className="py-2 px-3 text-center border">
                          <div className="flex justify-center items-center">
                            <TbNotes
                              className="text-[18px] cursor-pointer hover:text-gray-300"
                              onClick={() => setSelectedNote("to change")}
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="py-2 px-3 text-center border" colSpan={12}>
                        {isLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          "Tidak ada produk"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ModalNoteInbox note={selectedNote} setNote={setSelectedNote} />
    </>
  );
};

export default InboxDetailWarehouse;
