import { Box, Modal, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { IoWarningOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalNoteInbox = ({ note, setNote, mutate }) => {
  const [newNote, setNewNote] = useState("");

  const handleClose = () => {
    setNote(null);
  };

  useEffect(() => {
    setNewNote(note);
  }, [note]);

  return (
    <Modal open={note} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          maxWidth: "75%",
          overflowY: "auto",
          overflowX: "wrap",
          maxHeight: "60vh",
          padding: 0,
        }}
        className="text-left text-base w-3/4 lg:w-1/3"
      >
        <div className="flex justify-between items-center px-8 py-4">
          <div className="w-12 h-12 bg-blue-100 p-1 rounded-full">
            <div className="w-full h-full bg-blue-300 p-2 rounded-full">
              <IoWarningOutline className="w-full h-full text-blue-600" />
            </div>
          </div>
          <RxCross1
            className="cursor-pointer hover:text-red-500"
            onClick={handleClose}
          />
        </div>

        <div className="px-8 mb-4">
          <p className="font-bold text-lg ">Notes</p>
          <p>Enter the product notes down below</p>
        </div>

        <div className="px-8 mb-4">
          <TextField
            fullWidth
            multiline
            rows={3}
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            disabled
          />
        </div>

        <div className="px-8 mb-4 flex gap-2 items-center">
          <button
            type="button"
            className="w-full p-2 rounded-md border-2 border-black hover:bg-gray-300"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="w-full p-2 rounded-md border-2 border-blue-500 bg-blue-500 text-white hover:bg-gray-300 hover:border-gray-300"
            // onClick={() => {
            //   setConfirm(true);
            //   setOpen(false);
            // }}
          >
            Save
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalNoteInbox;
