import { MdOutlineFilterAlt } from "react-icons/md";
import BreadcrumbCentralWarehouse from "../BreadcrumbCentralWarehouse";
import {
  Checkbox,
  CircularProgress,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoCalendarOutline } from "react-icons/io5";
import { BsPrinter, BsTrash } from "react-icons/bs";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import PopupRangeCalendar from "../../../common/particles.jsx/PopupRangeCalendar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAllocationList } from "../../../../services/centralWarehouseApi";
import PagePagination from "../../../common/particles.jsx/PagePagination";
import { RxCross1 } from "react-icons/rx";
import { getOutletList } from "../../../../services/outletApi";

const TransferList = () => {
  const navigate = useNavigate();

  const [reqBody, setReqBody] = useState({
    type: 2, // 1: allocation, 2: trf, 3: retur to warehouse
    start_date: "2012-01-01",
    end_date: "2024-12-30",
    name: "",
    offset: 1,
    limit: 10,
  });
  const [anchorElCal, setAnchorElCal] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [outletArr, setOutletArr] = useState([]);
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [anchorElOutlet, setAnchorElOutlet] = useState(null);

  const handleSelectOutlet = (outlet) => {
    const index = outletArr.findIndex(
      (item) => item.company_id === outlet.company_id
    );
    if (index < 0) setOutletArr([...outletArr, outlet]);
    else
      setOutletArr([
        ...outletArr.slice(0, index),
        ...outletArr.slice(index + 1),
      ]);
  };

  const {
    data: dataList,
    mutate: mutateList,
    isLoading,
  } = useMutation({
    mutationFn: getAllocationList,
  });

  const { data: dataOutlet, isFetching: isFetchingOutlet } = useQuery({
    queryFn: () => getOutletList(),
    queryKey: ["outlets"],
  });

  useEffect(() => {
    mutateList(reqBody);
  }, [reqBody]);

  return (
    <>
      <BreadcrumbCentralWarehouse />
      <div className="flex items-center gap-2 mb-4">
        <TextField
          fullWidth
          placeholder="Search"
          value={reqBody.name}
          onChange={(e) =>
            setReqBody((prevValue) => ({
              ...prevValue,
              name: e.target.value,
            }))
          }
          inputProps={{ style: { padding: 8 } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        <div
          className="w-fit p-2 rounded-md border-2 flex gap-2 items-center text-gray-500 hover:bg-gray-100 cursor-pointer text-sm"
          onClick={(e) => setAnchorElCal(e.currentTarget)}
        >
          <IoCalendarOutline />
          <p className="whitespace-nowrap">
            {(reqBody.start_date === "") & (reqBody.end_date === "")
              ? "No Date Choosen"
              : moment(reqBody.start_date).format("DD MMM YYYY") +
                "-" +
                moment(reqBody.end_date).format("DD MMM YYYY")}
          </p>
          <FaChevronDown />
        </div>
        <div
          className="w-fit p-2 rounded-md border-2 flex gap-2 items-center text-gray-500 hover:bg-gray-100 cursor-pointer text-sm"
          onClick={(e) => setAnchorElFilter(e.currentTarget)}
        >
          <MdOutlineFilterAlt className="w-5 h-5" />
          <p>Filter</p>
          <FaChevronDown />
        </div>
        <button
          className="rounded-md p-2 border-2 border-gray-500 text-gray-500 text-sm flex gap-2 justify-center items-center hover:bg-gray-100 disabled:bg-gray-300 disabled:text-white"
          //   disabled={isLoadingXlsx || analysisArr.length === 0}
          //   onClick={handleExport}
        >
          Print
          <BsPrinter className="w-4 h-4" />
        </button>
        <div
          className="rounded-md p-2 flex justify-center items-center cursor-pointer hover:bg-gray-300 bg-blue-500 text-white"
          onClick={() =>
            navigate(`/central-warehouse/transfer/create-transfer`)
          }
        >
          <IoMdAddCircleOutline className="w-5 h-5" />
        </div>
      </div>

      <div className="flex w-full mb-4">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="py-2 text-center"></th>
                <th className="py-2 text-center">No</th>
                <th className="px-3 py-2 text-left whitespace-nowrap">
                  Transaction ID
                </th>
                <th className="px-3 py-2 text-left whitespace-nowrap">
                  Allocation Date
                </th>
                <th className="px-3 py-2 text-left whitespace-nowrap">
                  Outlet Destination
                </th>
                <th className="px-3 py-2 text-left whitespace-nowrap">User</th>
                <th className="px-3 py-2 text-left whitespace-nowrap">
                  Status
                </th>
                <th className="px-3 py-2 text-center whitespace-nowrap">
                  Action
                </th>
              </thead>
              <tbody>
                {dataList && !isLoading && dataList.request ? (
                  dataList.request.map((item, i) => (
                    <tr key={i}>
                      <td className="py-1 border text-center">
                        <Checkbox />
                      </td>
                      <td className="py-1 border text-center">{i + 1}</td>
                      <td className="px-3 py-1 border text-blue-500 cursor-pointer hover:text-blue-700 hover:underline">
                        {item.transaction_number}
                      </td>
                      <td className="px-3 py-1 border">
                        {moment(item.date).format("DD-MM-YYYY")}
                      </td>
                      <td className="px-3 py-1 border">{item.target}</td>
                      <td className="px-3 py-1 border">{item.created_by}</td>
                      <td className="px-3 py-1 border">
                        {item.status === 1 ? "In Progress" : "?"}
                      </td>
                      <td className="px-3 py-1 border bg-gray-100 text-center">
                        <div className="flex justify-center">
                          <BsTrash className="text-red-500 cursor-pointer hover:text-red-700" />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center px-3 py-1 border">
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        "No data found"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {dataList && !isLoading && dataList.count > 0 ? (
        <PagePagination
          offset={reqBody.offset}
          setOffset={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              offset: e,
            }));
          }}
          limit={reqBody.limit}
          setLimit={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              limit: e,
            }));
          }}
          total={dataList.count}
        />
      ) : null}

      <PopupRangeCalendar
        anchorElCal={anchorElCal}
        setAnchorElCal={setAnchorElCal}
        startDate={reqBody.start_date}
        endDate={reqBody.end_date}
        setStartDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, start_date: e }));
        }}
        setEndDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, end_date: e }));
        }}
      />

      <Popover
        open={Boolean(anchorElFilter)}
        anchorEl={anchorElFilter}
        onClose={() => setAnchorElFilter(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="px-8 py-2 w-full min-w-[20vw]">
          <div className=" grid grid-cols-3 items-center gap-4">
            <FaChevronLeft
              className="hover:text-red-500"
              onClick={() => setAnchorElFilter(null)}
            />
            <p className="font-bold text-center">Filter</p>
            <p
              className="text-red-500 cursor-pointer hover:text-gray-500 text-right"
              // onClick={resetReq}
            >
              Reset
            </p>
          </div>
        </div>
        <hr />
        <div
          className="px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 flex justify-between items-center"
          onClick={(e) => setAnchorElStatus(e.currentTarget)}
        >
          <p>Status</p>
          <FaChevronRight />
        </div>
        <div>
          <div
            className="px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 flex justify-between items-center"
            onClick={(e) => setAnchorElOutlet(e.currentTarget)}
          >
            <p>Outlet</p>
            <FaChevronRight />
          </div>
          <div className="p-2 grid grid-cols-2 gap-2">
            {outletArr.length > 0 ? (
              outletArr.map((item) => (
                <div className="flex items-center justify-between gap-2 bg-gray-200 p-1 rounded-md w-full">
                  <p>{item.company_name}</p>
                  <RxCross1
                    className="text-red-500 cursor-pointer hover:text-red-700"
                    onClick={() => {
                      const index = outletArr.findIndex(
                        (outlet) => outlet == item
                      );
                      setOutletArr([
                        ...outletArr.slice(0, index),
                        ...outletArr.slice(index + 1),
                      ]);
                    }}
                  />
                </div>
              ))
            ) : (
              <p className="text-gray-300 w-full ml-8">No outlet selected</p>
            )}
          </div>
        </div>
      </Popover>

      <Popover
        open={Boolean(anchorElStatus)}
        anchorEl={anchorElStatus}
        onClose={() => setAnchorElStatus(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="px-8 py-2 w-full">
          <div className=" grid grid-cols-3 items-center gap-4">
            <FaChevronLeft
              className="hover:text-red-500"
              onClick={() => setAnchorElStatus(null)}
            />
            <p className="font-bold text-center">Status</p>
          </div>
        </div>
        <hr />
        <div
          className={`px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 ${
            // status === 0 ? "bg-gray-200" : ""
            ""
          }`}
          // onClick={() => setStatus(0)}
        >
          All
        </div>
        <div
          className={`px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 ${
            // status === 1 ? "bg-gray-200" : ""
            ""
          }`}
          // onClick={() => setStatus(1)}
        >
          In progress
        </div>
        <div
          className={`px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 ${
            // status === 2 ? "bg-gray-200" : ""
            ""
          }`}
          // onClick={() => setStatus(2)}
        >
          Accepted
        </div>
        <div
          className={`px-8 py-2 hover:bg-gray-300 cursor-pointer ${
            // status === 2 ? "bg-gray-200" : ""
            ""
          }`}
          // onClick={() => setStatus(2)}
        >
          Rejected
        </div>
      </Popover>

      <Popover
        open={Boolean(anchorElOutlet)}
        anchorEl={anchorElOutlet}
        onClose={() => setAnchorElOutlet(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="px-8 py-2 w-full">
          <div className=" grid grid-cols-3 items-center gap-4">
            <FaChevronLeft
              className="hover:text-red-500"
              onClick={() => setAnchorElOutlet(null)}
            />
            <p className="font-bold text-center">Outlet</p>
          </div>
        </div>
        <hr />
        {dataOutlet && !isLoading ? (
          dataOutlet.map((outlet, i) => (
            <div
              key={i}
              className={`px-8 py-2 hover:bg-gray-300 cursor-pointer ${
                i !== dataOutlet.length - 1 ? "border-b-2" : ""
              } 
              ${outletArr.includes(outlet) ? "bg-gray-100" : ""}`}
              onClick={() => handleSelectOutlet(outlet)}
            >
              {outlet.company_name}
            </div>
          ))
        ) : (
          <div className="w-full flex justify-center my-4">
            {isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <p>Outlet tidak ditemukan</p>
            )}
          </div>
        )}
      </Popover>
    </>
  );
};

export default TransferList;
