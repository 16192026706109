import Cookies from "js-cookie";
import axios from "axios";

const token = () => Cookies.get("accessToken");

export const getAllocationList = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}warehouse/request-list`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getCartWarehouse = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}warehouse/product-cart`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getAllocationProduct = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}warehouse/product-list`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const setCartWarehouse = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}warehouse/product-save`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const setAllocation = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}warehouse/product-request`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getDetailWarehouse = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}warehouse/request-detail`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getWarehouseInboxList = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}warehouse/inbox-list`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getWarehouseInboxDetail = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}warehouse/inbox-detail`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};
