import { useEffect, useState } from "react";

import NotFound from "../../../assets/images/not_found.png";
import PromoCard from "./PromoCard";
import { useNavigate } from "react-router-dom";
import AddPromoProduct from "./AddPromoProduct";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { setNewPromo } from "../../../services/invoiceApi";
import * as yup from "yup";
import moment from "moment";

const validationSchema = yup.object({
  warehouse: yup.boolean(),
  promo: yup
    .array()
    .of(
      yup.object({
        promo_type: yup.number().min(1).required(),
        product_id: yup.number().required(),
        product_name: yup.string().required(),
        batch_number: yup.string().required(),
        packaging: yup.string().required(),
        expired_date: yup.string().required(),
        total_discount: yup.number(),
        on_us: yup.number(),
        off_us: yup.number(),
        quantity_bought: yup.number(),
        quantity_get: yup.number(),
        is_multiple: yup.boolean(),
        max_item: yup.number(),
        period_start: yup.string().required(),
        period_end: yup.string().required(),
      })
    )
    .min(1),
});

const CreatePromo = ({ isWarehouse }) => {
  const navigate = useNavigate();

  const [isAddProduct, setIsAddProduct] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isFilled = () => {
    var isValid = true;
    formik.values.promo.map((item) => {
      if (
        item.promo_type === 0 ||
        (item.promo_type === 1 && item.total_discount === 0) ||
        (item.promo_type === 2 &&
          (item.quantity_bought === 0 ||
            item.quantity_get === 0 ||
            item.max_item === 0)) ||
        moment(item.period_start).isSameOrAfter(item.period_end)
      )
        isValid = false;
    });
    return isValid;
  };

  const formik = useFormik({
    initialValues: {
      promo: [],
      warehouse: isWarehouse ? isWarehouse : false,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      mutateNewPromo(values);
    },
  });

  const { mutate: mutateNewPromo } = useMutation({
    mutationFn: setNewPromo,
    onSuccess: () => {
      navigate(`${isWarehouse ? "/central-warehouse" : ""}/invoice/promo`);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  return (
    <>
      {!isAddProduct ? (
        <>
          <div className="flex justify-end">
            <button
              className="py-2 px-12 border-2 border-gray-300 hover:bg-slate-100 rounded-md mt-4"
              onClick={() => setIsAddProduct(true)}
            >
              Tambah Produk
            </button>
          </div>
          <hr className="my-4" />
          {formik.values.promo.length === 0 ? (
            <div className="text-center w-full mt-12">
              <div className="flex justify-center mb-8">
                <img src={NotFound} className="w-[150px]" />
              </div>
              <div>Belum ada promo</div>
              <div className="text-gray-300">Tambahkan produk promo</div>
            </div>
          ) : (
            <>
              {formik.values.promo.map((item, i) => (
                <div className="my-2 w-full lg:w-[calc(100vw-350px)]" key={i}>
                  <PromoCard formik={formik} promo={item} index={i} />
                </div>
              ))}
            </>
          )}
          <form onSubmit={formik.handleSubmit}>
            <p className="text-red-500 text-center mt-4 mb-2">{errorMessage}</p>
            <div className="flex gap-2 my-4">
              <button
                className="w-full border-2 p-2 border-gray-300 rounded-md hover:bg-slate-100 cursor-pointer"
                onClick={() =>
                  navigate(
                    `${isWarehouse ? "/central-warehouse" : ""}/invoice/promo`
                  )
                }
              >
                Cancel
              </button>
              <button
                className="w-full p-2 rounded-md bg-blue-500 hover:bg-blue-700 text-white cursor-pointer disabled:bg-gray-300"
                type="submit"
                disabled={!formik.isValid || !isFilled()}
              >
                Save
              </button>
            </div>
          </form>
        </>
      ) : (
        <AddPromoProduct setIsAddProduct={setIsAddProduct} formik={formik} />
      )}
    </>
  );
};

export default CreatePromo;
