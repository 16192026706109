import { useParams } from "react-router-dom";
import BreadcrumbCentralWarehouse from "../BreadcrumbCentralWarehouse";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { getDetailWarehouse } from "../../../../services/centralWarehouseApi";
import { useEffect, useState } from "react";
import { TbNotes } from "react-icons/tb";
import ModalNoteAllocation from "./ModalNoteAllocation";

const DetailAllocation = () => {
  const { id } = useParams();

  const [selectedNote, setSelectedNote] = useState(null);

  const {
    mutate: mutateDetail,
    isLoading,
    data: dataDetail,
  } = useMutation({
    mutationFn: getDetailWarehouse,
  });

  useEffect(() => {
    mutateDetail({ id: Number(id) });
  }, [id]);

  return (
    <>
      <BreadcrumbCentralWarehouse />
      <div className="flex gap-4 items-center mb-4">
        <div className="w-full">
          {dataDetail && !isLoading ? (
            <>
              {" "}
              <div className="flex gap-2 items-center">
                <p className="w-1/3">Transaction ID</p>
                <p>: {dataDetail.header.transaction_number}</p>
              </div>
              <div className="flex gap-2 items-center">
                <p className="w-1/3">Allocation Date</p>
                <p>: {moment(dataDetail.header.date).format("DD/MM/YYYY")}</p>
              </div>
              <div className="flex gap-2 items-center">
                <p className="w-1/3">Outlet Destination</p>
                <p>: {dataDetail.header.target}</p>
              </div>
              <div className="flex gap-2 items-center">
                <p className="w-1/3">User</p>
                <p>: {dataDetail.header.created_by}</p>
              </div>
            </>
          ) : isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <p>Terjadi kesalahan dalam mengambil data</p>
          )}
        </div>
        <div className="basis-1/2">
          {dataDetail && !isLoading ? (
            <>
              <div
                className={`border-2 w-full rounded-lg p-2 text-center mb-2 ${
                  dataDetail.header.status === 1
                    ? ""
                    : dataDetail.header.status === 2
                    ? "border-blue-500 text-blue-500 bg-blue-100"
                    : "border-red-500 text-red-500 bg-red-100"
                }`}
              >
                {dataDetail.header.status === 1
                  ? "In Progress"
                  : dataDetail.header.status === 2
                  ? "Accepted"
                  : "Rejected"}
              </div>
              {dataDetail.header.status > 1 ? (
                <p>
                  {dataDetail.header.status === 2 ? "Accepted " : "Rejected"}{" "}
                  on: {moment().format("DD MMMM YYYY")}
                </p>
              ) : null}
            </>
          ) : null}
        </div>
      </div>

      <p className="font-bold">Selected Product</p>
      <hr className="my-2" />
      <div className="flex w-full mb-4">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="py-2 text-center">No</th>
                <th className="px-3 py-2 text-left whitespace-nowrap">
                  Product Name
                </th>
                <th className="px-3 py-2 text-left whitespace-nowrap">
                  Expired Date
                </th>
                <th className="px-3 py-2 text-left whitespace-nowrap">UOM</th>
                <th className="px-3 py-2 text-center whitespace-nowrap">Qty</th>
                <th className="px-3 py-2 text-center whitespace-nowrap">
                  Qty Received
                </th>
                <th className="px-3 py-2 text-center whitespace-nowrap">
                  Qty Back
                </th>
                <th className="px-3 py-2 text-left whitespace-nowrap">
                  Final Price
                </th>
                <th className="px-3 py-2 text-left whitespace-nowrap">Total</th>
                <th className="px-3 py-2 text-left whitespace-nowrap">
                  Reason
                </th>
                <th className="px-3 py-2 text-center whitespace-nowrap">
                  Notes
                </th>
              </thead>
              <tbody>
                {dataDetail && !isLoading && dataDetail.product ? (
                  dataDetail.product.map((item, i) => (
                    <tr key={i}>
                      <td className="px-3 py-2 border text-center">{i + 1}</td>
                      <td className="px-3 py-2 border  text-left">
                        {item.product_name}
                      </td>
                      <td className="px-3 py-2 border text-left">
                        {moment(item.expired_date).format("DD-MM-YYYY")}
                      </td>
                      <td className="px-3 py-2 border text-left">{item.uom}</td>
                      <td className="px-3 py-2 border text-center">
                        {item.quantity}
                      </td>
                      <td className="px-3 py-2 border text-center">
                        {item.quantity_received}
                      </td>
                      <td className="px-3 py-2 border text-center">
                        {item.quantity_back}
                      </td>
                      <td className="px-3 py-2 border text-left">{item.hna}</td>
                      <td className="px-3 py-2 border text-left">
                        {item.total}
                      </td>
                      <td className="px-3 py-2 border text-left">
                        {item.reason}
                      </td>
                      <td className="px-3 py-2 border text-center">
                        <div
                          className="flex justify-center"
                          onClick={() => setSelectedNote(item.notes)}
                        >
                          <TbNotes className="text-[18px] cursor-pointer hover:text-gray-300" />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="px-3 py-2 border text-center" colSpan={11}>
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Tidak ada produk"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ModalNoteAllocation note={selectedNote} setNote={setSelectedNote} />
    </>
  );
};

export default DetailAllocation;
