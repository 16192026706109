import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Switch,
  CircularProgress,
  Pagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  InputAdornment,
} from "@mui/material";
import DatePicker from "react-datepicker";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

import EmptyList from "../../ui/EmptyList";
import EditPromoStatusModal from "./EditPromoStatusModal";
import { getPromoList } from "../../../services/invoiceApi";
import { IoMdCalendar } from "react-icons/io";

const FETCH_LIMIT = 10;

const SettingPromo = ({ isWarehouse }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchPromo, setSearchPromo] = useState(0);
  const [searchExpired, setSearchExpired] = useState(moment().format("YYYY"));
  const [searchPeriod, setSearchPeriod] = useState(0);
  const [offset, setOffset] = useState(1);
  const [tanggalMulai, setTanggalMulai] = useState("");
  const [tanggalAkhir, setTanggalAkhir] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [date, setDate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [openExpired, setOpenExpired] = useState(false);
  const [expStart, setExpStart] = useState("");

  const {
    mutate: mutatePromoList,
    data,
    isLoading,
  } = useMutation({
    mutationFn: getPromoList,
    onSuccess: () => setIsChanged(false),
  });

  const onChange = useCallback(
    (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    },
    [startDate, endDate]
  );

  useEffect(() => {
    if (
      moment(startDate).format("YYYY-MM-DD") === "Invalid date" ||
      moment(endDate).format("YYYY-MM-DD") === "Invalid date"
    ) {
      setTanggalMulai("");
      setTanggalAkhir("");
    } else {
      setTanggalMulai(moment(startDate).format("YYYY-MM-DD"));
      setTanggalAkhir(moment(endDate).format("YYYY-MM-DD"));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const body = {
      limit: FETCH_LIMIT,
      offset: offset,
      promo_type: searchPromo,
      period: searchPeriod,
      period_start: tanggalMulai,
      period_end: tanggalAkhir,
      exp_year: expStart === "" ? "" : moment(expStart).format("YYYY"),
      warehouse: isWarehouse ? isWarehouse : false,
    };
    mutatePromoList(body);
  }, [
    offset,
    searchPromo,
    searchPeriod,
    tanggalMulai,
    tanggalAkhir,
    expStart,
    isChanged,
    location.pathname,
  ]);

  return (
    <>
      {
        <>
          <div className="w-full flex gap-2 justify-between mt-4">
            <FormControl fullWidth>
              <InputLabel>Tipe Promo</InputLabel>
              <Select
                value={searchPromo}
                label="Tipe Promo"
                onChange={(e) => setSearchPromo(e.target.value)}
              >
                <MenuItem value={0}>Semua Promo</MenuItem>
                <MenuItem value={1}>Discount</MenuItem>
                <MenuItem value={2}>Free Item</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              value={
                expStart === ""
                  ? "Semua Expire Date"
                  : moment(expStart).format("YYYY")
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IoMdCalendar />
                  </InputAdornment>
                ),
              }}
              onClick={() => setOpenExpired(true)}
            />
            <FormControl fullWidth>
              <InputLabel>Periode Promo</InputLabel>
              <Select
                value={searchPeriod}
                label="Periode Promo"
                onChange={(e) => {
                  setSearchPeriod(e.target.value);
                  if (e.target.value === 9) setDate(true);
                  else {
                    setStartDate(new Date());
                    setEndDate(null);
                    setDate(false);
                  }
                }}
              >
                <MenuItem value={0}>Semua Periode</MenuItem>
                <MenuItem value={1}>Hari Ini</MenuItem>
                <MenuItem value={2}>Kemarin</MenuItem>
                <MenuItem value={3}>Minggu Berjalan</MenuItem>
                <MenuItem value={4}>Minggu Lalu</MenuItem>
                <MenuItem value={5}>Bulan Berjalan</MenuItem>
                <MenuItem value={6}>Bulan Lalu</MenuItem>
                <MenuItem value={7}>Tahun Berjalan</MenuItem>
                <MenuItem value={8}>Tahun Lalu</MenuItem>
                <MenuItem value={9}>Pilih Periode</MenuItem>
                <MenuItem value={10} disabled sx={{ display: "none" }}>
                  {tanggalMulai + " - " + tanggalAkhir}
                </MenuItem>
              </Select>
            </FormControl>
          </div>

          <>
            {data && data.total > 0 && data.data !== null && !isLoading ? (
              <div>
                <div className="flex justify-between my-4 items-center">
                  <p className="font-bold text-xl">List of Promo</p>
                  <div>
                    <button
                      className="border-2 border-gray-200 rounded-md hover:bg-slate-100 px-12 py-2 flex items-center gap-2"
                      onClick={() =>
                        navigate(
                          `${
                            isWarehouse ? "/central-warehouse" : ""
                          }/invoice/promo/create-promo`
                        )
                      }
                    >
                      Tambah Promo
                    </button>
                  </div>
                </div>

                <div className="block overflow-x-scroll w-full  ">
                  <table className="w-full border">
                    <thead className="bg-blue-500 text-white text-center">
                      <tr>
                        <th className="py-2 border">Nama Produk</th>
                        <th className="py-2 border">No. Batch</th>
                        <th className="py-2 border">Tanggal Expired Produk</th>
                        <th className="py-2 border">Discount</th>
                        <th className="py-2 border">Promo Item</th>
                        <th className="py-2 border">Periode</th>
                        <th className="py-2 border">Status</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {data.data !== null && data.data.length > 0 ? (
                        <>
                          {data.data.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td className="p-2 border">
                                  {item.product_name}
                                </td>
                                <td className="p-2 border">{item.batch_no}</td>
                                <td className="py-2 border whitespace-nowrap">
                                  {moment(item.product_expired).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td className="p-2 border">
                                  {item.discount > 0
                                    ? item.discount + "%"
                                    : "-"}
                                </td>
                                <td className="p-2 border">
                                  {item.promo !== "Beli 0, gratis 0"
                                    ? item.promo
                                    : "-"}
                                </td>
                                <td className="p-2 border whitespace-nowrap">
                                  {moment(item.start_date).format("DD-MM-YYYY")}{" "}
                                  - {moment(item.end_date).format("DD-MM-YYYY")}
                                </td>
                                <td className="p-2 border">
                                  <Switch
                                    checked={item.is_active}
                                    onClick={() => {
                                      setSelectedPromo(item);
                                      setOpenModal(true);
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : isLoading ? (
              <div className="mt-8">
                <CircularProgress />
              </div>
            ) : (
              <div className="mt-12">
                <EmptyList message="Belum ada promo saat ini" />
                <div className="w-full flex justify-center">
                  <Button
                    variant="contained"
                    sx={{ paddingX: 8 }}
                    onClick={() =>
                      navigate(
                        `${
                          isWarehouse ? "/central-warehouse" : ""
                        }/invoice/promo/create-promo`
                      )
                    }
                  >
                    Tambah Promo
                  </Button>
                </div>
              </div>
            )}
          </>
        </>
      }

      <div className="flex justify-end mt-4">
        {data && data.data !== null && data.data.length > 0 ? (
          <Pagination
            count={Math.ceil(data.total / FETCH_LIMIT)}
            page={offset}
            onChange={(_, value) => setOffset(value)}
            shape="rounded"
            size="large"
            color="primary"
          />
        ) : null}
      </div>

      <Dialog
        open={date}
        onClose={() => {
          setDate(false);
        }}
      >
        <DialogTitle>Pilih Tanggal</DialogTitle>
        <DialogContent>
          <div className="flex">
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          </div>
          <button
            className="bg-indigo-500 rounded text-white px-2 py-1 font-semibold"
            onClick={() => {
              setDate(false);
              setSearchPeriod(10);
            }}
          >
            ok
          </button>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openExpired}
        onClose={() => {
          setOpenExpired(false);
        }}
      >
        <DialogTitle className="text-center">
          Pilih Tahun Expired Obat
        </DialogTitle>
        <DialogContent>
          <div className="flex justify-center">
            <DatePicker
              selected={expStart}
              onChange={(date) => setExpStart(date)}
              showYearPicker
              dateFormat="yyyy"
              inline
            />
          </div>
          <Button
            variant="outlined"
            fullWidth
            sx={{ marginTop: 2 }}
            onClick={() => setExpStart("")}
          >
            Tampilkan Semua
          </Button>
        </DialogContent>
      </Dialog>

      <EditPromoStatusModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        id={selectedPromo?.promo_id}
        newStatus={!selectedPromo?.is_active}
        setIsChanged={setIsChanged}
      />
    </>
  );
};

export default SettingPromo;
