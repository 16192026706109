import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoCalendarOutline } from "react-icons/io5";
import {
  MdCheckCircleOutline,
  MdOutlineCancel,
  MdOutlineFilterAlt,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import PopupRangeCalendar from "../../../common/particles.jsx/PopupRangeCalendar";
import moment from "moment";
import { useMutation } from "@tanstack/react-query";
import { getWarehouseInboxList } from "../../../../services/centralWarehouseApi";
import useDebounce from "../../../hooks/useDebounce";
import PagePagination from "../../../common/particles.jsx/PagePagination";
import ModalAcceptWarehouse from "./ModalAcceptWarehouse";
import ModalDeleteWarehouse from "./ModalDeleteWarehouse";

const TRANSACTION_TYPE = [
  { id: 0, name: "All" },
  { id: 1, name: "Allocation" },
  { id: 2, name: "Transfer" },
  { id: 3, name: "Send to Warehouse" },
  { id: 4, name: "Request Allocation" },
  { id: 5, name: "Request Transfer" },
];

const STATUS_TYPE = [
  { id: 0, name: "All" },
  { id: 1, name: "In Progress" },
  { id: 2, name: "Accepted" },
  { id: 3, name: "Rejected" },
];

const InboxWarehouseList = () => {
  const navigate = useNavigate();

  const [reqBody, setReqBody] = useState({
    type: 1,
    start_date: "2024-01-01",
    end_date: "2028-12-12",
    limit: 10,
    offset: 1,
  });
  const [search, setSearch] = useState("");
  const [anchorElCal, setAnchorElCal] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElSubfilter, setAnchorElSubfilter] = useState(null);
  const [typeSubfilter, setTypeSubfilter] = useState("");
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [openAccept, setOpenAccept] = useState(false);
  const [openReject, setOpenReject] = useState(false);

  const debounce = useDebounce(search, 500);

  const {
    mutate: mutateList,
    isLoading,
    data: dataList,
  } = useMutation({
    mutationFn: getWarehouseInboxList,
  });

  useEffect(() => {
    mutateList({ ...reqBody, name: debounce });
  }, [reqBody, debounce]);

  useEffect(() => {
    setReqBody((prevValue) => ({ ...prevValue, offset: 1 }));
  }, [debounce]);

  return (
    <>
      <div className="flex items-center gap-2 mb-4">
        <TextField
          fullWidth
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          inputProps={{ style: { padding: 8 } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        <div
          className="w-fit p-2 rounded-md border-2 flex gap-2 items-center text-gray-500 hover:bg-gray-100 cursor-pointer text-sm"
          onClick={(e) => setAnchorElCal(e.currentTarget)}
        >
          <IoCalendarOutline />
          <p className="whitespace-nowrap">
            {(reqBody.start_date === "") & (reqBody.end_date === "")
              ? "No Date Choosen"
              : moment(reqBody.start_date).format("DD MMM YYYY") +
                "-" +
                moment(reqBody.end_date).format("DD MMM YYYY")}
          </p>
          <FaChevronDown />
        </div>
        <div
          className="w-fit p-2 rounded-md border-2 flex gap-2 items-center text-gray-500 hover:bg-gray-100 cursor-pointer text-sm"
          onClick={(e) => setAnchorElFilter(e.currentTarget)}
        >
          <MdOutlineFilterAlt className="w-5 h-5" />
          <p>Filter</p>
          <FaChevronDown />
        </div>
      </div>

      <div className="flex w-full mb-4">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="py-2 text-center">No</th>
                <th className="px-3 py-2 text-left whitespace-nowrap">
                  Transaction ID
                </th>
                <th className="px-3 py-2 text-left whitespace-nowrap">Date</th>
                <th className="px-3 py-2 text-left whitespace-nowrap">
                  Transaction Type
                </th>
                <th className="px-3 py-2 text-left whitespace-nowrap">From</th>
                <th className="px-3 py-2 text-left whitespace-nowrap">To</th>
                <th className="px-3 py-2 text-left whitespace-nowrap">User</th>
                <th className="py-2 text-center">Action</th>
              </thead>
              <tbody>
                {dataList && !isLoading && dataList.request ? (
                  dataList.request.map((item, i) => (
                    <tr key={i}>
                      <td className="px-3 py-2 border text-center">1</td>
                      <td className="px-3 py-2 border text-left text-blue-500 hover:underline hover:text-blue-700 cursor-pointer">
                        aa
                      </td>
                      <td className="px-3 py-2 border text-left">
                        {moment().format("DD-MM-YYYY")}
                      </td>
                      <td className="px-3 py-2 border text-left">dsf</td>
                      <td className="px-3 py-2 border text-left">ss</td>
                      <td className="px-3 py-2 border text-left">sfsf</td>
                      <td className="px-3 py-2 border text-left">sfsf</td>
                      <td className="px-3 py-2 border text-center">
                        <div className="flex gap-2 items-center justify-center">
                          <MdOutlineCancel
                            className="text-[18px] text-red-500 cursor-pointer hover:text-gray-300"
                            onClick={() => {
                              setSelectedRequest("ini");
                              setOpenReject(true);
                            }}
                          />
                          <MdCheckCircleOutline
                            className="text-[18px] text-blue-500 cursor-pointer hover:text-gray-300"
                            onClick={() => {
                              setSelectedRequest("ini");
                              setOpenAccept(true);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="px-3 py-2 border text-center" colSpan={8}>
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Data tidak ditemukan"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {dataList && !isLoading && dataList.count > 0 ? (
        <PagePagination
          offset={reqBody.offset}
          setOffset={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              offset: e,
            }));
          }}
          limit={reqBody.limit}
          setLimit={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              limit: e,
            }));
          }}
          total={dataList.count}
        />
      ) : null}

      <Popover
        open={Boolean(anchorElFilter)}
        anchorEl={anchorElFilter}
        onClose={() => setAnchorElFilter(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="px-8 py-2 w-full min-w-[20vw]">
          <div className=" grid grid-cols-3 items-center gap-4">
            <FaChevronLeft
              className="hover:text-red-500"
              onClick={() => setAnchorElFilter(null)}
            />
            <p className="font-bold text-center">Filter</p>
            <p
              className="text-red-500 cursor-pointer hover:text-gray-500 text-right"
              // onClick={resetReq}
            >
              Reset
            </p>
          </div>
        </div>
        <hr />
        <div
          className="px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 flex justify-between items-center"
          onClick={(e) => {
            setAnchorElSubfilter(e.currentTarget);
            setTypeSubfilter("transaction");
          }}
        >
          <p>Transaction Type</p>
          <FaChevronRight />
        </div>
        <div
          className="px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 flex justify-between items-center"
          onClick={(e) => {
            setAnchorElSubfilter(e.currentTarget);
            setTypeSubfilter("status");
          }}
        >
          <p>Status</p>
          <FaChevronRight />
        </div>
        <div>
          <div
            className="px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 flex justify-between items-center"
            onClick={(e) => {
              setAnchorElSubfilter(e.currentTarget);
              setTypeSubfilter("source");
            }}
          >
            <p>Source</p>
            <FaChevronRight />
          </div>
          <div className="p-2 grid grid-cols-2 gap-2">
            {/* {outletArr.length > 0 ? (
              outletArr.map((item) => (
                <div className="flex items-center justify-between gap-2 bg-gray-200 p-1 rounded-md w-full">
                  <p>{item.company_name}</p>
                  <RxCross1
                    className="text-red-500 cursor-pointer hover:text-red-700"
                    onClick={() => {
                      const index = outletArr.findIndex(
                        (outlet) => outlet == item
                      );
                      setOutletArr([
                        ...outletArr.slice(0, index),
                        ...outletArr.slice(index + 1),
                      ]);
                    }}
                  />
                </div>
              ))
            ) : (
              <p className="text-gray-300 w-full ml-8">No outlet selected</p>
            )} */}
            <p className="text-gray-300 w-full ml-8">No source selected</p>
          </div>
        </div>
      </Popover>

      <Popover
        open={Boolean(anchorElSubfilter)}
        anchorEl={anchorElSubfilter}
        onClose={() => setAnchorElSubfilter(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="px-8 py-2 w-full">
          <div className=" grid grid-cols-3 items-center gap-4">
            <FaChevronLeft
              className="hover:text-red-500"
              onClick={() => setAnchorElSubfilter(null)}
            />
            <p className="font-bold text-center">
              {typeSubfilter === "transaction"
                ? "Transaction Type"
                : typeSubfilter === "status"
                ? "Status"
                : "Source"}
            </p>
          </div>
        </div>
        <hr />
        <div className="px-8 py-2">
          {typeSubfilter === "transaction" || typeSubfilter === "status"
            ? ""
            : "No source"}
        </div>
        {(typeSubfilter === "transaction"
          ? TRANSACTION_TYPE
          : typeSubfilter === "status"
          ? STATUS_TYPE
          : []
        ).map((item, i) => (
          <div
            className={`px-8 py-2 hover:bg-gray-300 cursor-pointer ${
              i <
              (typeSubfilter === "transaction"
                ? TRANSACTION_TYPE
                : typeSubfilter === "status"
                ? STATUS_TYPE
                : []
              ).length -
                1
                ? "border-b-2"
                : ""
            } ${
              // status === 0 ? "bg-gray-200" : ""
              ""
            }`}
            // onClick={() => setStatus(0)}
          >
            {item.name}
          </div>
        ))}
      </Popover>

      <PopupRangeCalendar
        anchorElCal={anchorElCal}
        setAnchorElCal={setAnchorElCal}
        startDate={reqBody.start_date}
        endDate={reqBody.end_date}
        setStartDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, start_date: e }));
        }}
        setEndDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, end_date: e }));
        }}
      />

      <ModalAcceptWarehouse open={openAccept} setOpen={setOpenAccept} />
      <ModalDeleteWarehouse open={openReject} setOpen={setOpenReject} />
    </>
  );
};

export default InboxWarehouseList;
