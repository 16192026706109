import { Breadcrumbs, Link } from "@mui/material";
import { useLocation } from "react-router-dom";

const BreadcrumbCentralWarehouse = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Breadcrumbs sx={{ color: "#1761FD", marginBottom: 2 }}>
        <Link
          href="/home"
          underline="hover"
          color="#1761FD"
          sx={{ color: "#1761FD" }}
        >
          Homepage
        </Link>
        <Link color="#1761FD" underline="none" sx={{ color: "#1761FD" }}>
          Central Warehouse
        </Link>
        {pathname.includes("/central-warehouse/allocation") ? (
          <Link
            underline="none"
            color="#1761FD"
            href="/central-warehouse/allocation"
            sx={{ color: "#1761FD" }}
          >
            Allocation
          </Link>
        ) : null}
        {pathname.includes(
          "/central-warehouse/allocation/create-allocation"
        ) ? (
          <Link
            underline="none"
            color="#1761FD"
            href="/central-warehouse/allocation/create-allocation"
            sx={{ color: "#1761FD" }}
          >
            New Allocation
          </Link>
        ) : null}
        {pathname.includes("/central-warehouse/allocation/detail/") ? (
          <Link underline="none" color="#1761FD" sx={{ color: "#1761FD" }}>
            Allocation Details
          </Link>
        ) : null}
        {pathname.includes("/central-warehouse/transfer") ? (
          <Link
            underline="none"
            color="#1761FD"
            href="/central-warehouse/transfer"
            sx={{ color: "#1761FD" }}
          >
            Transfer
          </Link>
        ) : null}
        {pathname.includes("/central-warehouse/transfer/create-transfer") ? (
          <Link
            underline="none"
            color="#1761FD"
            href="/central-warehouse/transfer/create-transfer"
            sx={{ color: "#1761FD" }}
          >
            New Transfer
          </Link>
        ) : null}
        {pathname.includes("/central-warehouse/retur-warehouse") ? (
          <Link
            underline="none"
            color="#1761FD"
            href="/central-warehouse/retur-warehouse"
            sx={{ color: "#1761FD" }}
          >
            Retur to warehouse
          </Link>
        ) : null}
        {pathname.includes(
          "/central-warehouse/retur-warehouse/create-retur-warehouse"
        ) ? (
          <Link
            underline="none"
            color="#1761FD"
            href="/central-warehouse/transfer/create-retur-warehouse"
            sx={{ color: "#1761FD" }}
          >
            New Retur
          </Link>
        ) : null}
      </Breadcrumbs>
    </>
  );
};

export default BreadcrumbCentralWarehouse;
