import Cookies from "js-cookie";
import axios from "axios";
import { GeneratePublicToken } from "../utils/GeneratePublicToken";

const token = () => Cookies.get("accessToken");

export const getProductInventory = async (limit, offset, name, status) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}product/inventories?limit=${limit}&offset=${offset}&name=${name}&status=${status}`,
      data: {
        limit,
        offset,
        name,
        status,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const deactiveProduct = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_PRODUCT}product/status`,
      data: body,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const updateProduct = async ({ values }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/information`,
      method: "PUT",
      data: values,
    };
    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};
export const updateStocksPrice = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_PRODUCT}product/update/several`,
      // data: {
      //   product_update: values,
      // },
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchUpdateStocks = async ({ productId, warehouse }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}product/update/several`,
      data: {
        warehouse: warehouse ? warehouse : false,
        id: productId,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getDetailInventory = async (id, warehouse) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }product/detail?id=${id}&warehouse=${warehouse ? warehouse : false}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getCategory = async (limit, offset, status) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },

      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}product/category?limit=${limit}&offset=${offset}&status=${status}`,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getCategoryMaster = async (limit, offset, name) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];
  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },

      method: "GET",
      url: `${process.env.REACT_APP_API_MASTER}category/web?limit=${limit}&offset=${offset}&name=${name}`,
    };

    const res = await axios(config);
    return res.data.detail.category;
  } catch (err) {
    throw err;
  }
};

export const getPrincipal = async (limit, offset, name) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];
  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_MASTER}principal/web?limit=${limit}&offset=${offset}&name=${name}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail.principal;
  } catch (err) {
    throw err;
  }
};

export const getGroups = async () => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];
  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_MASTER}label`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchUpdateProduct = async ({ id, warehouse }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/update/several`,
      method: "POST",
      data: {
        id,
        warehouse: warehouse ? warehouse : false,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getPlatform = async () => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];
  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/platform`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchPrincipal = async ({ status, limit, name, offset }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/principals?status=${status}`,
      method: "POST",
      data: {
        limit,
        name,
        offset,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchProductList = async ({
  status,
  limit,
  name,
  offset,
  type,
  list_id,
  principal_id,
  sort,
  warehouse,
}) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/inventories?status=${status}&limit=${limit}&name=${name}&offset=${offset}&principal_id=${principal_id}&sort=${sort}`,
      method: "POST",
      data: {
        type: type,
        list_id: list_id,
        warehouse: warehouse ? warehouse : false,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const activateProducts = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/status`,
      method: "PUT",
      data: body,
    };
    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteProducts = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "DELETE",
      url: `${process.env.REACT_APP_API_PRODUCT}product`,
      data: body,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const uploadImage = async (image) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
        "Content-Type": "multipart/form-data",
      },
      url: `${process.env.REACT_APP_API_MASTER}upload/image`,
      method: "POST",
      data: {
        image: image,
        type: "public",
        datasource: "company",
      },
    };

    const res = await axios(config);
    return res.data.detail.url;
  } catch (err) {
    throw err;
  }
};

export const deleteImage = async (url) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_MASTER}file/remove`,
      method: "DELETE",
      data: {
        url: url,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getNIE = async (nie, limit, offset) => {
  const regex = /\(\d+\)(\w+)\(\d+\)\d+/;
  const result = nie.match(regex);
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}product/master?nie=${
        result && result[1] ? result[1] : nie
      }&limit=${limit}&offset=${offset}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getNIEElasticSearch = async (nie, limit, offset, warehouse) => {
  const regex = /\(\d+\)(\w+)\(\d+\)\d+/;
  const result = nie.match(regex);
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }product/master/find-by-elastic?keyword=${
        result && result[1] ? result[1] : nie
      }&limit=${limit}&offset=${offset}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getNieDetail = async (id, bpomId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}product/master/detail?id=${id}&bpom_id=${bpomId}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getPackaging = async () => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];
  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_MASTER}packaging`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const createProduct = async (values) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}product`,
      data: values,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const fetchStockoutProducts = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/stock/several`,
      method: "POST",
      data: id,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchExpiredProducts = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/expired/several`,
      method: "POST",
      data: id,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchPrintSP = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/return/sp`,
      method: "POST",
      data: body,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchReturnExpiredProduct = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/return/exp`,
      method: "POST",
      data: body,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const groupingProduct = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}grouping-cetak-sp`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const createAdjustmentStock = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/adjustment`,
      method: "POST",
      data: {
        data: body,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getAdjustmentStock = async (
  offset,
  limit,
  period,
  start_date,
  end_date,
  type_adjustment,
  warehouse
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }product/adjustments?offset=${offset}&limit=${limit}&period=${period}&start_date=${start_date}&end_date=${end_date}&type_adjustment=${type_adjustment}&warehouse=${
        warehouse ? warehouse : false
      }`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getAdjustmentDetail = async (pa_id, product_id, warehouse) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/stock-adjustments/detail?pa_id=${pa_id}&product_id=${product_id}&warehouse=${warehouse}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getOutlet = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/outlet`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getStockOpnameSession = async (
  offset,
  limit,
  status,
  start_date,
  end_date,
  period,
  warehouse
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }product/stockopname?offset=${offset}&limit=${limit}&status=${status}&start_date=${start_date}&end_date=${end_date}&period=${period}&warehouse=${
        warehouse ? warehouse : false
      }`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getStockOpnameEndSession = async (
  ps_id,
  product_name,
  created_by,
  offset,
  limit,
  warehouse
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }product/stockopname/end-session?ps_id=${ps_id}&product_name=${product_name}&created_by=${created_by}&offset=${offset}&limit=${limit}&warehouse=${
        warehouse ? warehouse : false
      }`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getStockOpnameEndSessionDetail = async (
  product_id,
  ps_id,
  warehouse
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }product/stockopname/detail?product_id=${product_id}&ps_id=${ps_id}&warehouse=${
        warehouse ? warehouse : false
      }`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const searchProductAdjustment = async (data, warehouse) => {
  const regex = /\(\d+\)(\w+)\(\d+\)\d+/;
  const result = data.match(regex);
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }search/product/adjustment?data=${
        result && result[1] ? result[1] : data
        // data
      }&warehouse=${warehouse ? warehouse : false}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const createProductAdjustment = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/adjustment`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const createStockopname = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/stockopname/create`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const createStockOpnameSession = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/stockopname/create-session`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const createStockOpnameForEndSession = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/stockopname/create`,
      method: "POST",
      data: {
        data: body,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const createStockHistory = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/stockopname-create-history`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getCardStock = async (limit, offset, product_name) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/cardstock-history?limit=${limit}&offset=${offset}&product_name=${product_name}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const fetchCardStockNew = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/cardstock-history-new`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getDetailCardStock = async (product_id, relational_id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/cardstock-history-detail?product_id=${product_id}&relational_id=${relational_id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getSearchStockOpname = async (product_id, warehouse) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ` + token() },
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }product/stockopname/get-search?product_id=${product_id}&warehouse=${
        warehouse ? warehouse : false
      }`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const changeStatusReminderStock = async ({ product_id, warehouse }) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ` + token() },
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }product/change/status/reminder?product_id=${product_id}&warehouse=${
        warehouse ? warehouse : false
      }`,
      method: "POST",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const exportXlsxCardStock = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ` + token() },
      url: `${process.env.REACT_APP_API_PRODUCT}product/cardstock-history-export-new`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const exportXlsxCardInventorySum = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ` + token() },
      url: `${process.env.REACT_APP_API_PRODUCT}product/summary-inventories-export`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const exportXlsxInventory = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ` + token() },
      url: `${process.env.REACT_APP_API_PRODUCT}product/csv/download-csv`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getProductPos = async (limit, offset, method, name) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ` + token() },
      url: `${process.env.REACT_APP_API_PRODUCT}product/pos?limit=${limit}&offset=${offset}&method=${method}&name=${name}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchSummaryInventories = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ` + token() },
      url: `${process.env.REACT_APP_API_PRODUCT}product/summary-inventories`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getBatchDetailCardStockNew = async (
  product_id,
  company_id,
  warehouse
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }product/cardstock-history-batch?product_id=${product_id}&company_id=${company_id}&warehouse=${
        warehouse ? warehouse : false
      }`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getDetailCardStockNew = async (
  product_id,
  company_id,
  batch_id,
  warehouse
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }product/cardstock-history-detail-new?product_id=${product_id}&company_id=${company_id}&batch_id=${batch_id}&warehouse=${
        warehouse ? warehouse : false
      }`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getSummaryInventory = async (
  product_id,
  company_id,
  limit,
  offset
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/summary-inventory?product_id=${product_id}&company_id=${company_id}&limit=${limit}&offset=${offset}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getBatchNumberDate = async (product_id, date) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/date-batches?product_id=${product_id}&date=${date}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getDetailSummaryInventory = async (
  product_id,
  company_id,
  limit,
  offset,
  warehouse
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }product/summary-inventories-detail?product_id=${product_id}&company_id=${company_id}&limit=${limit}&offset=${offset}&warehouse=${
        warehouse ? warehouse : false
      }`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};
