import { Box, Modal } from "@mui/material";
import { MdCheckCircleOutline } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalAcceptWarehouse = ({ open, setOpen, setConfirm }) => {
  const handleClose = () => {
    setConfirm(false);
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          maxWidth: "75%",
          overflowY: "auto",
          overflowX: "wrap",
          maxHeight: "50vh",
          padding: 0,
        }}
        className="text-left text-base w-3/4 lg:w-1/3"
      >
        <div className="flex justify-between items-center px-8 py-4">
          <div className="w-12 h-12 bg-blue-100 p-1 rounded-full">
            <div className="w-full h-full bg-blue-300 p-2 rounded-full">
              <MdCheckCircleOutline className="w-full h-full text-blue-600" />
            </div>
          </div>
          <RxCross1
            className="cursor-pointer hover:text-red-500"
            onClick={handleClose}
          />
        </div>

        <div className="px-8 mb-4">
          <p className="font-bold text-lg ">Accept allocation</p>
          <p>
            Are you sure you want to accept the allocated product to your
            inventory?
          </p>
        </div>

        <div className="px-8 mb-4 flex gap-2 items-center">
          <button
            type="button"
            className="w-full p-2 rounded-md border-2 border-black hover:bg-gray-300"
            onClick={() => {
              setOpen(false);
              setConfirm(false);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="w-full p-2 rounded-md border-2 border-blue-500 bg-blue-500 text-white hover:bg-gray-300 hover:border-gray-300"
            onClick={() => {
              setConfirm(true);
              setOpen(false);
            }}
          >
            Accept
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalAcceptWarehouse;
