import { SIDEBAR_WIDTH } from "../../../components/constants/constant";
import InboxDetailWarehouse from "../../../components/screen/centralWarehouse/inbox/InboxDetailWarehouse";
import Header from "../../../components/ui/Header";
import SidebarComponents from "../../../components/ui/SidebarComponents";

const DetailInboxWarehousePage = () => {
  return (
    <div className="">
      <div className="flex">
        <SidebarComponents />
        <div
          className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}
        >
          <Header />
          <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
            Detail Inbox
          </h1>
          <InboxDetailWarehouse />
        </div>
      </div>
    </div>
  );
};

export default DetailInboxWarehousePage;
