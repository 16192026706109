import { Search } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import { useMutation } from "@tanstack/react-query";
import {
  getAllocationProduct,
  setCartWarehouse,
} from "../../../../services/centralWarehouseApi";
import InfiniteScroll from "react-infinite-scroll-component";
import { LuMinusCircle, LuPlusCircle } from "react-icons/lu";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";
import { IoIosArrowRoundBack } from "react-icons/io";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const FETCH_LIMIT = 5;

const ModalAddProductAllocation = ({ open, setOpen, refetch }) => {
  const [search, setSearch] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(1);
  const [items, setItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [addProductArr, setAddProductArr] = useState([]);

  const debounce = useDebounce(search, 500);

  const getStockOnHands = (id) => {
    const product = addProductArr.find((prod) => prod.id === id);
    return product.packaging.find((pack) => pack.uom === product.type_stock)
      .stock;
  };

  const handleCheckItem = (checked, item) => {
    var temp = [...addProductArr];
    if (checked) {
      temp.push({ ...item, stock: 0, type_stock: 0 });
    } else {
      const index = temp.findIndex((prod) => prod.id === item.id);
      temp = [...temp.slice(0, index), ...temp.slice(index + 1)];
    }
    setAddProductArr(temp);
  };

  const handlePackaging = (id, e) => {
    var temp = [...addProductArr];
    const index = addProductArr.findIndex((prod) => prod.id === id);
    temp[index].type_stock = e.target.value;
    temp[index].stock = 0;
    setAddProductArr(temp);
  };

  const changeStock = (id, action, val) => {
    var temp = [...addProductArr];
    const index = addProductArr.findIndex((prod) => prod.id === id);
    const maxVal = getStockOnHands(id);

    if (action === "min") temp[index].stock -= 1;
    if (action === "add")
      temp[index].stock =
        temp[index].stock + 1 <= maxVal ? temp[index].stock + 1 : maxVal;
    if (action === "text")
      isNaN(Number(val))
        ? (temp[index].stock = 0)
        : (temp[index].stock = Number(val) <= maxVal ? Number(val) : maxVal);
    setAddProductArr(temp);
  };

  const handleSave = () => {
    mutateAddCart({
      type: 1,
      product: addProductArr.map((item) => ({
        id: item.id,
        uom: item.type_stock,
        quantity: item.stock,
      })),
    });
  };

  const handleClose = () => {
    setSearch("");
    setOffset(1);
    setAddProductArr([]);
    setOpen(false);
  };

  const {
    mutate: mutateProduct,
    data,
    isLoading: isLoadingProduct,
  } = useMutation({
    mutationFn: getAllocationProduct,
    onSuccess: (data) => {
      if (data && data.product && offset === 1) setItems(data.product);
      else if (data && data.product && offset > 1) {
        var temp = [...items];
        temp.push(...data.product);
        setItems(temp);
      }

      if (data.prouduct && data.count <= FETCH_LIMIT) setHasMore(false);
      if (data.product && data.count > FETCH_LIMIT) setOffset(offset + 1);
    },
  });

  const { mutate: mutateAddCart, isLoading: isLoadingAddCart } = useMutation({
    mutationFn: setCartWarehouse,
    onSuccess: () => {
      refetch();
      handleClose();
    },
  });

  useEffect(() => {
    if (open)
      mutateProduct({
        name: debounce,
        type: 1,
        limit: FETCH_LIMIT,
        offset: offset,
      });
  }, [debounce, offset, open]);

  useEffect(() => {
    if (data && data.product === null) {
      setHasMore(false);
    }
    if (data && data.product !== null) {
      data.product.length > 0 ? setHasMore(true) : setHasMore(false);
    }
  }, [data]);

  useEffect(() => {
    setHasMore(true);
    setOffset(1);
  }, [debounce]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            maxWidth: "75%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "80vh",
            padding: 0,
          }}
          className="text-left text-base p-4"
          id="scrollableDiv"
        >
          <LoadingBackdrop isLoading={isLoadingAddCart} />
          <div className="flex gap-4 items-center mb-3">
            <IoIosArrowRoundBack
              className="hover:text-slate-300 cursor-pointer text-[30px]"
              onClick={handleClose}
            />
            <p className="font-semibold text-[20px] lg:text-[24px]">
              Add Product
            </p>
          </div>
          <div className="flex gap-2 mb-4">
            <TextField
              fullWidth
              placeholder="Search product"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <button
              className="bg-blue-500 rounded-md text-white py-2 px-12 hover:bg-gray-300"
              onClick={handleSave}
            >
              Save
            </button>
          </div>

          <div className="block overflow-x-auto my-4">
            <InfiniteScroll
              className="w-full"
              dataLength={items && items.length ? items.length : 0}
              next={() => {
                setOffset(offset + 1);
              }}
              hasMore={hasMore}
              loader={hasMore ? <CircularProgress size={20} /> : null}
              scrollableTarget="scrollableDiv"
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Tidak ada produk lagi</b>
                </p>
              }
            >
              <div className="flex w-full mb-4">
                <div className="w-full">
                  <div className="block overflow-x-auto w-full rounded-md">
                    <table className="w-full overflow-x-auto rounded-md border-collapse">
                      <thead className="bg-blue-500 text-white">
                        <th className="py-2 text-center"></th>
                        <th className="px-3 py-2 text-left whitespace-nowrap">
                          Product Name
                        </th>
                        <th className="px-3 py-2 text-center whitespace-nowrap">
                          UOM
                        </th>
                        <th className="px-3 py-2 text-center whitespace-nowrap">
                          Stock on Hand
                        </th>
                        <th className="px-3 py-2 text-center whitespace-nowrap">
                          Qty
                        </th>
                      </thead>
                      <tbody>
                        {items && items.length > 0
                          ? items.map((item, i) => (
                              <tr key={i}>
                                <td className="border text-center">
                                  <Checkbox
                                    onChange={(_, checked) =>
                                      handleCheckItem(checked, item)
                                    }
                                    checked={
                                      addProductArr.find(
                                        (prod) => prod.id === item.id
                                      ) !== undefined
                                    }
                                  />
                                </td>
                                <td className="px-3 py-2 border text-left">
                                  {item.product_name}
                                </td>
                                <td className="px-3 py-2 border text-center">
                                  {item.packaging ? (
                                    <FormControl fullWidth>
                                      <Select
                                        disabled={
                                          !addProductArr.find(
                                            (prod) => prod.id === item.id
                                          )
                                        }
                                        value={
                                          !addProductArr.find(
                                            (prod) => prod.id === item.id
                                          )
                                            ? 0
                                            : addProductArr[
                                                addProductArr.findIndex(
                                                  (prod) => prod.id === item.id
                                                )
                                              ].type_stock
                                        }
                                        onChange={(e) => {
                                          handlePackaging(item.id, e);
                                        }}
                                      >
                                        {item.packaging.map((pack) =>
                                          pack.uom < 2 ? (
                                            <MenuItem value={pack.uom}>
                                              {pack.name}
                                            </MenuItem>
                                          ) : null
                                        )}
                                      </Select>
                                    </FormControl>
                                  ) : null}
                                </td>
                                <td className="px-3 py-2 border text-center">
                                  {!addProductArr.find(
                                    (prod) => prod.id === item.id
                                  )
                                    ? item.packaging
                                      ? item.packaging[0].stock
                                      : 0
                                    : getStockOnHands(item.id)}
                                </td>
                                <td className="px-3 py-2 border text-center w-[13%]">
                                  <div className="flex justify-center bg-slate-100 rounded-md border-2 p-1">
                                    <button
                                      disabled={
                                        !addProductArr.find(
                                          (prod) => prod.id === item.id
                                        ) ||
                                        addProductArr.find(
                                          (prod) => prod.id === item.id
                                        ).stock === 0
                                      }
                                      onClick={() => {
                                        changeStock(item.id, "min");
                                      }}
                                      className="text-red-500 hover:text-red-700"
                                    >
                                      <LuMinusCircle className="text-[16px]" />
                                    </button>
                                    <input
                                      type="text"
                                      className="bg-slate-100 p-2 w-[80px] text-center"
                                      value={
                                        !addProductArr.find(
                                          (prod) => prod.id === item.id
                                        )
                                          ? 0
                                          : addProductArr[
                                              addProductArr.findIndex(
                                                (prod) => prod.id === item.id
                                              )
                                            ].stock
                                      }
                                      onChange={(e) =>
                                        changeStock(
                                          item.id,
                                          "text",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <button
                                      disabled={
                                        !addProductArr.find(
                                          (prod) => prod.id === item.id
                                        )
                                      }
                                      onClick={() => {
                                        changeStock(item.id, "add");
                                      }}
                                      className="text-blue-500 hover:text-blue-700"
                                    >
                                      <LuPlusCircle className="text-[16px]" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </InfiniteScroll>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ModalAddProductAllocation;
