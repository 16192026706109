import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { getExportBilling } from "../../../services/billingAPI";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { getPoList } from "../../../services/purchaseOrderApi";

const FETCH_LIMIT = 10000;

const ExportBillingModal = ({ setOpenModal, openModal, isWarehouse }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPO, setSelectedPO] = useState(null);
  const [openPO, setOpenPO] = useState(false);
  const [searchPO, setSearchPO] = useState("");

  const {
    mutate: mutatePoList,
    data,
    isLoading,
  } = useMutation({
    mutationFn: getPoList,
  });

  const { mutate: mutateExport } = useMutation({
    mutationFn: getExportBilling,
    onSuccess: (data) => {
      window.open(data.detail);
      setOpenModal(false);
    },
    onError: (err) => {
      setErrorMessage(err.id);
    },
  });

  useEffect(() => {
    if (openModal) {
      const body = {
        search: "",
        limit: FETCH_LIMIT,
        offset: 1,
        type: 4,
        sort: 0,
        start_date: "",
        end_date: "",
        warehouse: isWarehouse ? isWarehouse : false,
      };
      mutatePoList(body);
    }
  }, [openModal]);

  return (
    <>
      {openModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-8 max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="p-4 w-full">
                  <p className="text-center font-medium text-[30px] mb-3">
                    Export Billing
                  </p>
                  <p>Pilih Nomor PO yang ingin anda export</p>
                  <Autocomplete
                    fullWidth
                    loading={isLoading}
                    open={openPO}
                    onOpen={() => setOpenPO(true)}
                    onClose={() => setOpenPO(false)}
                    disablePortal
                    options={data && data.data !== null ? data.data : []}
                    value={selectedPO}
                    onChange={(_, inputValue) => {
                      setSelectedPO(inputValue);
                    }}
                    onInputChange={(_, onInputValue, reason) => {
                      if (reason === "input") {
                        setSearchPO(onInputValue);
                      }
                    }}
                    getOptionLabel={(option) => option.po_number || ""}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.po_number}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>

                <p className="text-red-500 mb-4 mx-4">{errorMessage}</p>

                <div className="flex items-center justify-center p-2 gap-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-red-400 text-white hover:bg-red-600 font-bold uppercase text-sm w-full py-2 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    onClick={() => {
                      setErrorMessage("");
                      setSelectedPO(null);
                      setOpenModal(false);
                    }}
                  >
                    Batal
                  </button>
                  <button
                    className="disabled:bg-gray-300 bg-orange-400 text-white hover:bg-orange-600 font-bold uppercase text-sm w-full py-2 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    onClick={() =>
                      mutateExport(
                        selectedPO.id,
                        isWarehouse ? isWarehouse : false
                      )
                    }
                    disabled={selectedPO === null}
                  >
                    Konfirmasi
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ExportBillingModal;
